import React from "react";
import DateContainer from "../Component/DateContainer";
import "./DateScreen.css";

export default function DateScreen() {
  return (
    <div id="date-home">
      <h1>Événements | Dates | Concerts</h1>

      <div id="date-block">
        <div className="dates">
          <div className="first_year">2025</div>

          <DateContainer
            dayName="samedi"
            day="25"
            month="janvier"
            city="Podensac"
            hour="20h30"
          />
          <DateContainer
            dayName="vendredi"
            day="28"
            month="fevrier"
            city="Parentis en Born"
            hour="20h30"
          />
          <DateContainer
            dayName="samedi"
            day="8"
            month="mars"
            city="Salle La Ruche - Saucats"
            hour="20h00"
          />
          <DateContainer
            dayName="jeudi"
            day="1"
            month="mai"
            city="Grottes de Lonbrives (09)"
            confirm="À confirmer"
          />
          <DateContainer
            dayName="vendredi"
            day="26"
            month="septembe"
            city="Langoiran"
            eventDesc="Première partie - Irish Mass"
            hour="20h30"
          />
          <DateContainer
            dayName="vendredi"
            day="28"
            month="novembre"
            city="Camblanes et Meynac"
            eventDesc="Première partie - Irish Mass"
            hour="20h30"
          />
          <DateContainer
            dayName="vendredi"
            day="12"
            month="décembre"
            city="Cambes"
            eventDesc="Première partie - Irish Mass"
            hour="20h30"
          />

          <div className="first_year">2024</div>
          <DateContainer
            dayName="dimanche"
            day="15"
            month="décembre"
            city="Vendays Montalivet"
          />
          <DateContainer
            dayName="samedi"
            day="7"
            month="décembre"
            city="Eglise Notre-Dame - Créon"
            hour="20h00"
          />
          <DateContainer
            dayName="dimanche"
            day="24"
            month="novembre"
            city="Salle de Cabrales - Sadirac"
            hour="15h30"
          />
          <DateContainer
            dayName="samedi"
            day="16"
            month="novembre"
            city="Eglise de Baron"
            eventDesc="Première partie - Jubilate Deo - Dan Forrest"
            hour="20h30"
          />
          <DateContainer
            dayName="vendredi"
            day="15"
            month="novembre"
            city="Eglise de Camblanes et Meynac"
            eventDesc="Première partie - Jubilate Deo - Dan Forrest"
            hour="20h30"
          />
          <DateContainer
            dayName="Samedi"
            month="Octobre"
            day="12"
            city="Cubzenais"
            eventDesc="Première partie - Symphonique Adiémus - K Jenkins"
          />
          <DateContainer
            dayName="Vendredi"
            month="Octobre"
            day="11"
            city="Camblanes et Meynac"
            eventDesc="Première partie - Jubilate Deo - Dan Forrest"
          />

          <DateContainer
            dayName="Samedi"
            day="21"
            month="septembre"
            city="Talais"
          />
          <DateContainer
            dayName="Vendredi"
            day="28"
            month="juin"
            city="Langoiran"
            eventDesc="Première partie - Jubilate Deo - Dan Forrest"
          />
          <DateContainer
            dayName="Vendredi"
            day="22"
            month="juin"
            city="Saint Symphorien"
          />
          <DateContainer
            dayName="juin"
            city="Domaine de Nodris - Vertheuil"
            eventDesc="Jubilate Deo"
            confirm="À confirmer"
          />
          <DateContainer
            dayName="samedi"
            day="13"
            month="avril"
            city="Oviedo (Espagne)"
          />
          <DateContainer
            dayName="vendredi"
            day="12"
            month="avril"
            city="Oviedo (Espagne)"
          />

          <div className="second_year">2023</div>
          <DateContainer
            dayName="samedi"
            day="16"
            month="décembre"
            city="Sadirac"
          />
          <DateContainer
            dayName="vendredi"
            day="15"
            month="décembre"
            city="Montussan"
          />
          <DateContainer
            dayName="samedi"
            day="2"
            month="décembre"
            city="Verines (17)"
          />
          <DateContainer
            dayName="vendredi"
            day="10"
            month="novembre"
            city="Camblanes et Meynac"
            eventDesc="Adiemus symphonique"
          />
          <DateContainer
            dayName="mardi"
            day="10"
            month="octobre"
            city="Baron"
            eventDesc="Adiemus symphonique"
          />
          <DateContainer
            dayName="mercredi"
            day="12"
            month="juillet"
            city="Talais"
          />
          <DateContainer
            dayName="dimanche"
            day="2"
            month="juillet"
            city="Domaine de Nodris - Vertheuil"
          />
          <DateContainer
            dayName="vendredi"
            day="30"
            month="juin"
            city="Langoiran"
            eventDesc="Adiemus symphonique"
          />
          <DateContainer
            dayName="vendredi"
            day="16"
            month="juin"
            city="Thairé (17)"
          />
          <DateContainer
            dayName="jeudi"
            day="1"
            month="juin"
            city="Chapelle de Meynac"
            eventDesc="Les romantiques allemands"
          />
          <DateContainer
            dayName="samedi"
            day="7"
            month="janvier"
            city="Podensac"
          />
        </div>
      </div>
    </div>
  );
}
