import React from "react";
import Slider from "../Component/Slider.js";

export default function HomeScreen() {
  return (
    <div>
      <div className="slider">
        <Slider />
      </div>
      <div className="description">
        <div className="first-part">
          <div className="first-line-part">
            <h1>
              Concerts,
              <br /> Festivals,
              <br />
              Concerts privés,
              <br />
              Événementiel
            </h1>
            <h2>
              Une quinzaine de femmes
              <br />
              regroupées autour
              <br />
              <strong>
                de l'amour de la musique
                <br />
                et du chant polyphonique
              </strong>
            </h2>
          </div>
          <main>
            <h3>LES DAMES DE CHŒUR</h3>
            <p>
              Cet ensemble vocal 100% féminin rassemble une quinzaine de femmes
              venues d’horizons différents, mais qui ont toutes une passion
              commune pour le chant <strong>polyphonique</strong>. Elles aiment
              entremêler leur voix, partager entre elles et le public le plaisir
              de la musique, du chant et de la polyphonie.
              <br />
              C’est avec beaucoup d’aisance et d’élégance qu’elles se promènent
              dans des styles et des répertoires très variés allant ainsi
              de&nbsp;<strong>la musique classique</strong> (verdi, saint Saëns,
              Poulenc), aux <strong>chants traditionnels</strong> ( Norvège,
              Italie, Irlande Bulgarie…) n’hésitant pas à explorer toutes les
              formes de polyphonie les plus inattendues, ainsi que quelques
              compositeurs contemporains qui œuvrent actuellement pour le
              renouveau de la musique chorale dans le monde. A la direction de
              cet ensemble vocal : <a href="/">Marie-Cécile Robin-Héraud</a>.
              Cette chanteuse lyrique suit actuellement une carrière de soprano,
              mais c’est une touche à tout, un caméléon de la voix, on la
              retrouve dans plusieurs formations et dans des styles musicaux
              très divers (classiques, traditionnels, jazz, chanson, gospel…) et
              notamment avec le fameux{" "}
              <a href="/">https://barber-shop-quartet.net/</a> qui arpente
              depuis plusieurs années les scènes nationales et internationales.
              Les « Dames de Chœur » vous proposent un voyage musical dans le
              temps et l’espace et saurons vous charmer de leurs voix et créer
              avec le public une grande complicité.
            </p>
          </main>
        </div>
      </div>
    </div>
  );
}
